
.w-50-100 {
    width: 50%!important;
}

@media (max-width: 1000px) {
    .w-50-100 {
        width: 100%!important;
        /* padding: 0px!important;
        padding: 0px!important; */

    }
}
