.fill-white {
    /* fill: #ffffff; */
    filter: invert(100%) sepia(7%) saturate(0%) hue-rotate(40deg) brightness(107%) contrast(107%);
  }

  .height-50-100 {
    min-height: 50vh;
  }

  
  @media (max-width: 1200px) {

  .star-container {
    align-content: center;
  }

}
