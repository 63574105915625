nav {
  /* background: linear-gradient(var(--background),transparent); */
  /* background-color: var(--background); */
  background: rgb(33,47,60);
  background: linear-gradient(0deg, rgba(33,47,60,0) 0%, rgba(33,47,60,1) 100%);
}
@media (max-width: 1200px) {
  .lg-nav {
    display: none!important;
  }
}

@media (max-width: 1200px) {
    .social-container {
      display: none;
    }
  }

  @media (min-width: 1200px) {
    .social-dropdown {
      display: none;
    }
  }

  .icons {
    filter: invert(100%);
    height: 30px;
    margin: 10px;
  }

  .social-container {
    display:flex;
    padding-right: 20px;
    position: right!important;
    float:right;
  }


  .offCanvas {
    background-color: var(--background);
  }

  .menu-button-container {

  }
  .menu-button-img {
    filter: invert(100%);
    width: 3rem;

  }

  .menu-button {
    all: unset;
  }

  @media (min-width: 1200px) {
    .menu-button {
      display: none;

    }
  }

.navbar-link {
  text-decoration: none;
  font-weight: 500;

}

.right-side {
  position: absolute;
  right: 0px;
}