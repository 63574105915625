@import './csstemplates/main.css';
@import './index.css';
@import './scss/custom.css';
@import './Fonts.css';
@import './Hero.css';
@import './About.css';
@import './Navbar.css';
@import './contact.css';
@import './preorder.css';
@import './reviews.css';
/* @import './csstemplates/custom.scss'; */


:root {
  --primary: #2E83B7;
  --secondary: #E77338;
  --success: #289A82;
  --danger: #D25425;
  --info: #50A6D3;
  --background: #212f3c;
}

.largeSpace {
  height: 50vh;
}

@media (max-width: 1000px) {
  .largeSpace{
    height: 10vh;

  }
  
}

.blankspace {
  height: 5vh;
}

body {
  background-color: var(--background);
  color: white;
}


.z--2 {
  z-index:-2;
}

.bg-bright {
  background-color:#374450!important;
}

.pill {
  border-radius: 206px!important;
}


ul {
  /* text-align: center; */
  list-style-position: outside;
}

.bookcover-ratio {
  aspect-ratio: 0.65!important;
}