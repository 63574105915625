@font-face {
    font-family: 'Libre Baskerville', serif;
    src: url('./fonts/LibreBaskerville-Regular.ttf') format('truetype');
    font-weight: normal;
    style: normal;
  }

  @font-face {
    font-family: 'Libre Baskerville', serif;
    src: url('./fonts/LibreBaskerville-Bold.ttf') format('truetype');
    font-weight: bold;
    style: normal;
  }

@font-face {
    font-family: 'Libre Baskerville', serif;
    src: url('./fonts/LibreBaskerville-Italic.ttf') format('truetype');
    font-weight: normal;
    style: italic;
  }

  body {
    font-family: Baskerville, serif;
  }

  .font-18 {
    font-size: 36px;
    z-index: 2;
  }

  @media (min-width: 1000px) {
    .text-center-left {
      text-align: center!important;
  
    }
  }

  @media (max-width: 1000px) {
    .text-center-left {
      text-align: left!important;
  
    }
  }

  /* .text-center-left {
    text-align: left;
    color: blueviolet;
  }
   */